// Authentication
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

// Mobile
export const UPDATE_PROFILE_MOBILE = 'UPDATE_PROFILE_MOBILE';

// Snackbars
export const SET_SNACKBAR = 'SET_SNACKBAR';

// Users
export const IS_ADMIN = 'admin';
export const IS_MANAGER_BRAND = 'brand_manager';
export const IS_MANAGER_SHOP = 'shop_manager';
export const IS_EMPLOYEE_BRAND = 'brand_employee';
export const IS_EMPLOYEE_SHOP = 'shop_employee';

export const IS_ADMIN_VALUE = 'admin';
export const IS_MANAGER_BRAND_VALUE = 'brand_manager';
export const IS_MANAGER_SHOP_VALUE = 'shop_manager';
export const IS_EMPLOYEE_BRAND_VALUE = 'brand_employee';
export const IS_EMPLOYEE_SHOP_VALUE = 'shop_employee';

export const IS_ADMIN_LABEL = 'admin';
export const IS_MANAGER_BRAND_LABEL = 'Manager Brand';
export const IS_MANAGER_SHOP_LABEL = 'Manager PDV';
export const IS_EMPLOYEE_BRAND_LABEL = 'Employé Brand';
export const IS_EMPLOYEE_SHOP_LABEL = 'Employé PDV';

export const mapRole = {
  admin: IS_ADMIN_LABEL,
  IS_ADMIN_VALUE: IS_ADMIN_LABEL,
  IS_MANAGER_BRAND_VALUE: IS_MANAGER_BRAND_LABEL,
  IS_MANAGER_SHOP_VALUE: IS_MANAGER_SHOP_LABEL,
  IS_EMPLOYEE_BRAND_VALUE: IS_EMPLOYEE_BRAND_LABEL,
  IS_EMPLOYEE_SHOP_VALUE: IS_EMPLOYEE_SHOP_LABEL,
};

export const listRole = [
  { label: IS_ADMIN_LABEL, value: 'admin' },
  { label: IS_MANAGER_BRAND_LABEL, value: 'brand_manager' },
  { label: IS_MANAGER_SHOP_LABEL, value: 'shop_manager' },
  { label: IS_EMPLOYEE_BRAND_LABEL, value: 'brand_employee' },
  { label: IS_EMPLOYEE_SHOP_LABEL, value: 'shop_employee' },
];

export const listForAdmin = [
  { label: IS_ADMIN_LABEL, value: 'admin' },
  { label: IS_MANAGER_BRAND_LABEL, value: 'brand_manager' },
];

export const listForBrandManager = [
  { label: IS_MANAGER_SHOP_LABEL, value: 'shop_manager' },
  { label: IS_EMPLOYEE_BRAND_LABEL, value: 'brand_employee' },
  { label: IS_MANAGER_BRAND_LABEL, value: 'brand_manager' },
];

export const listForShopManager = [
  { label: IS_EMPLOYEE_SHOP_LABEL, value: 'shop_employee' },
  { label: IS_MANAGER_SHOP_LABEL, value: 'shop_manager' },
];

export const listForBrandSector = [
  { label: 'Alimentaire', value: 'food' },
  { label: 'Automobile', value: 'auto' },
  { label: 'Beauté', value: 'beauty' },
  { label: 'Bijoux', value: 'jewelry' },
  { label: 'Bricolage', value: 'craft' },
  { label: 'Bureautique', value: 'office' },
  { label: 'Carburant', value: 'fuel' },
  { label: 'Electroménager', value: 'home_appliance' },
  { label: 'Jeux/Jouets', value: 'games' },
  { label: 'Lavage automobile', value: 'car_wash' },
  { label: 'Librairie', value: 'bookstore' },
  { label: 'Maison/Déco', value: 'home_decoration' },
  { label: 'Restaurant', value: 'restaurant' },
  { label: 'Santé', value: 'health' },
  { label: 'Sport', value: 'sport' },
  { label: 'Transports', value: 'transports' },
  { label: 'Vêtements', value: 'clothes' },
];

export const listGender = [
  { label: 'Homme', value: 'male' },
  { label: 'Femme', value: 'female' },
];

//Brand
export const POST_BRAND = 'POST_BRAND';
export const POST_BRAND_CATEGORY = 'POST_BRAND_CATEGORY';
export const UPDATE_BRAND_CATEGORY = 'UPDATE_BRAND_CATEGORY';
export const REMOVE_EDITED_BRAND_CATEGORY =
  'REMOVE_EDITED_BRAND_CATEGORY';
export const DELETE_BRAND_CATEGORY = 'DELETE_BRAND_CATEGORY';
export const GET_BRAND = 'GET_BRAND';
export const GET_BRAND_CATEGORIES = 'GET_BRAND_CATEGORIES';
export const GET_BRAND_TICKETS = 'GET_BRAND_TICKETS';
export const GET_SINGLE_BRAND = 'GET_SINGLE_BRAND';
export const DELETE_BRAND = 'DELETE_BRAND';
export const UPDATE_BRAND = 'UPDATE_BRAND';
export const EDITED_BRAND = 'EDITED_BRAND';
export const REMOVE_EDITED_BRAND = 'REMOVE_EDITED_BRAND';
export const EXPORT_BRANDS = 'EXPORT_BRANDS';
export const EXPORT_BRAND_TICKETS = 'EXPORT_BRAND_TICKETS';

//Shop
export const GET_SHOP = 'GET_SHOP';
export const POST_SHOP = 'POST_SHOP';
export const DELETE_SHOP = 'DELETE_SHOP';
export const EDITED_SHOP = 'EDITED_SHOP';
export const UPDATE_SHOP = 'UPDATE_SHOP';
export const REMOVE_EDITED_SHOP = 'REMOVE_EDITED_SHOP';
// CLIENTS REVIEWS
export const GET_CLIENTS_REVIEWS = 'GET_CLIENTS_REVIEWS';
export const USER_REVIEW = 'USER_REVIEW';
export const EXPORT_CLIENTS_REVIEWS = 'EXPORT_CLIENTS_REVIEWS';

// CLIENTS
export const GET_LIST_CLIENTS = 'GET_LIST_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';

// CHAT
export const GET_LIST_CONVERSATION = 'GET_LIST_CONVERSATION';

//Users
export const GET_USER = 'GET_USER';
export const POST_USER = 'POST_USER';
export const EDITED_USER = 'EDITED_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_EDITED_USER = 'REMOVE_EDITED_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const EXPORT_USERS = 'EXPORT_USERS';
export const EXPORT_USER_TICKETS = 'EXPORT_USER_TICKETS';
export const GET_USERS= 'GET_USERS';

//Sector
export const EDITED_SECTOR = 'EDITED_SECTOR';

//Alert
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

//Tickets
export const GET_TICKETS = 'GET_TICKETS';
export const GET_SINGLE_USER_TICKETS = 'GET_SINGLE_USER';
export const EDITED_TICKET = 'EDITED_TICKET';
export const EXPORT_TICKETS = 'EXPORT_TICKETS';

export const IS_ACTIVE = 'IS_ACTIVE';

// Retailer Ads Action Types
export const FETCH_ADS_REQUEST = 'FETCH_ADS_REQUEST';
export const FETCH_ADS_SUCCESS = 'FETCH_ADS_SUCCESS';
export const FETCH_ADS_FAILURE = 'FETCH_ADS_FAILURE';
export const CREATE_AD_REQUEST = 'CREATE_AD_REQUEST';
export const CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS';
export const CREATE_AD_FAILURE = 'CREATE_AD_FAILURE';
export const FETCH_AD_DETAILS_REQUEST = 'FETCH_AD_DETAILS_REQUEST';
export const FETCH_AD_DETAILS_SUCCESS = 'FETCH_AD_DETAILS_SUCCESS';
export const FETCH_AD_DETAILS_FAILURE = 'FETCH_AD_DETAILS_FAILURE';
export const UPDATE_AD_REQUEST = 'UPDATE_AD_REQUEST';
export const UPDATE_AD_SUCCESS = 'UPDATE_AD_SUCCESS';
export const UPDATE_AD_FAILURE = 'UPDATE_AD_FAILURE';
export const DELETE_AD_REQUEST = 'DELETE_AD_REQUEST';
export const DELETE_AD_SUCCESS = 'DELETE_AD_SUCCESS';
export const DELETE_AD_FAILURE = 'DELETE_AD_FAILURE';

// Retailer Campaigns Action Types
export const FETCH_CAMPAIGNS_REQUEST = 'FETCH_CAMPAIGNS_REQUEST';
export const FETCH_CAMPAIGNS_SUCCESS = 'FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'FETCH_CAMPAIGNS_FAILURE';
export const CREATE_CAMPAIGN_REQUEST = 'CREATE_CAMPAIGN_REQUEST';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'CREATE_CAMPAIGN_FAILURE';
export const FETCH_CAMPAIGN_ADS_REQUEST =
  'FETCH_CAMPAIGN_ADS_REQUEST';
export const FETCH_CAMPAIGN_ADS_SUCCESS =
  'FETCH_CAMPAIGN_ADS_SUCCESS';
export const FETCH_CAMPAIGN_ADS_FAILURE =
  'FETCH_CAMPAIGN_ADS_FAILURE';
export const FETCH_CAMPAIGN_DETAILS_REQUEST =
  'FETCH_CAMPAIGN_DETAILS_REQUEST';
export const FETCH_CAMPAIGN_DETAILS_SUCCESS =
  'FETCH_CAMPAIGN_DETAILS_SUCCESS';
export const FETCH_CAMPAIGN_DETAILS_FAILURE =
  'FETCH_CAMPAIGN_DETAILS_FAILURE';
export const DELETE_CAMPAIGN_REQUEST = 'DELETE_CAMPAIGN_REQUEST';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAILURE = 'DELETE_CAMPAIGN_FAILURE';

export const EDIT_CAMPAIGN_REQUEST = 'EDIT_CAMPAIGN_REQUEST';
export const EDIT_CAMPAIGN_SUCCESS = 'EDIT_CAMPAIGN_SUCCESS';
export const EDIT_CAMPAIGN_FAILURE = 'EDIT_CAMPAIGN_FAILURE';
export const SET_SELECTED_CAMPAIGN_ID = 'SET_SELECTED_CAMPAIGN_ID';

export const FETCH_CAMPAIGN_STATS_REQUEST =
  'FETCH_CAMPAIGN_STATS_REQUEST';
export const FETCH_CAMPAIGN_STATS_SUCCESS =
  'FETCH_CAMPAIGN_STATS_SUCCESS';
export const FETCH_CAMPAIGN_STATS_FAILURE =
  'FETCH_CAMPAIGN_STATS_FAILURE';

export const UPDATE_CAMPAIGN_CLICKBID_REQUEST =
  'UPDATE_CAMPAIGN_CLICKBID_REQUEST';
export const UPDATE_CAMPAIGN_CLICKBID_SUCCESS =
  'UPDATE_CAMPAIGN_CLICKBID_SUCCESS';
export const UPDATE_CAMPAIGN_CLICKBID_FAILURE =
  'UPDATE_CAMPAIGN_CLICKBID_FAILURE';

export const FETCH_ADMIN_CAMPAIGNS_REQUEST =
  'FETCH_ADMIN_CAMPAIGNS_REQUEST';
export const FETCH_ADMIN_CAMPAIGNS_SUCCESS =
  'FETCH_ADMIN_CAMPAIGNS_SUCCESS';
export const FETCH_ADMIN_CAMPAIGNS_FAILURE =
  'FETCH_ADMIN_CAMPAIGNS_FAILURE';

// single campaign types
export const FETCH_ADMIN_CAMPAIGN_REQUEST =
  'FETCH_ADMIN_CAMPAIGN_REQUEST';
export const FETCH_ADMIN_CAMPAIGN_SUCCESS =
  'FETCH_ADMIN_CAMPAIGN_SUCCESS';
export const FETCH_ADMIN_CAMPAIGN_FAILURE =
  'FETCH_ADMIN_CAMPAIGN_FAILURE';

export const FETCH_ADMIN_CAMPAIGN_DETAILS_REQUEST =
  'FETCH_ADMIN_CAMPAIGN_DETAILS_REQUEST';
export const FETCH_ADMIN_CAMPAIGN_DETAILS_SUCCESS =
  'FETCH_ADMIN_CAMPAIGN_DETAILS_SUCCESS';
export const FETCH_ADMIN_CAMPAIGN_DETAILS_FAILURE =
  'FETCH_ADMIN_CAMPAIGN_DETAILS_FAILURE';

export const FETCH_ADMIN_CAMPAIGNS_STATS_REQUEST =
  'FETCH_ADMIN_CAMPAIGNS_STATS_REQUEST';
export const FETCH_ADMIN_CAMPAIGNS_STATS_SUCCESS =
  'FETCH_ADMIN_CAMPAIGNS_STATS_SUCCESS';
export const FETCH_ADMIN_CAMPAIGNS_STATS_FAILURE =
  'FETCH_ADMIN_CAMPAIGNS_STATS_FAILURE';

export const FETCH_ADMIN_CAMPAIGNS_ADS_REQUEST =
  'FETCH_ADMIN_CAMPAIGNS_ADS_REQUEST';
export const FETCH_ADMIN_CAMPAIGNS_ADS_SUCCESS =
  'FETCH_ADMIN_CAMPAIGNS_ADS_SUCCESS';
export const FETCH_ADMIN_CAMPAIGNS_ADS_FAILURE =
  'FETCH_ADMIN_CAMPAIGNS_ADS_FAILURE';

export const DELETE_ADMIN_CAMPAIGN_REQUEST =
  'DELETE_ADMIN_CAMPAIGN_REQUEST';
export const DELETE_ADMIN_CAMPAIGN_SUCCESS =
  'DELETE_ADMIN_CAMPAIGN_SUCCESS';
export const DELETE_ADMIN_CAMPAIGN_FAILURE =
  'DELETE_ADMIN_CAMPAIGN_FAILURE';

export const UPDATE_TOKEN_REQUEST = 'UPDATE_TOKEN_REQUEST';
export const UPDATE_TOKEN_SUCCESS = 'UPDATE_TOKEN_SUCCESS';
export const UPDATE_TOKEN_FAILURE = 'UPDATE_TOKEN FAILURE';

export const FETCH_DAILY_STATS_REQUEST = 'FETCH_DAILY_STATS_REQUEST';
export const FETCH_DAILY_STATS_SUCCESS = 'FETCH_DAILY_STATS_SUCCESS';
export const FETCH_DAILY_STATS_FAILURE = 'FETCH_DAILY_STATS_FAILURE';

export const FETCH_MONTHLY_STATS_REQUEST =
  'FETCH_MONTHLY_STATS_REQUEST';
export const FETCH_MONTHLY_STATS_SUCCESS =
  'FETCH_MONTHLY_STATS_SUCCESS';
export const FETCH_MONTHLY_STATS_FAILURE =
  'FETCH_MONTHLY_STATS_FAILURE';

export const FETCH_WEEKLY_STATS_REQUEST =
  'FETCH_WEEKLY_STATS_REQUEST';
export const FETCH_WEEKLY_STATS_SUCCESS =
  'FETCH_WEEKLY_STATS_SUCCESS';
export const FETCH_WEEKLY_STATS_FAILURE =
  'FETCH_WEEKLY_STATS_FAILURE';

export const FETCH_BRANDS_WITH_CAMPAIGN_REQUEST =
  'FETCH_BRANDS_WITH_CAMPAIGN_REQUEST';
export const FETCH_BRANDS_WITH_CAMPAIGN_SUCCESS =
  'FETCH_BRANDS_WITH_CAMPAIGN_SUCCESS';
export const FETCH_BRANDS_WITH_CAMPAIGN_FAILURE =
  'FETCH_BRANDS_WITH_CAMPAIGN_FAILURE';

export const FETCH_DAILY_ADMIN_STATS_BY_BRAND_REQUEST =
  'FETCH_DAILY_ADMIN_STATS_BY_BRAND_REQUEST';
export const FETCH_DAILY_ADMIN_STATS_BY_BRAND_SUCCESS =
  'FETCH_DAILY_ADMIN_STATS_BY_BRAND_SUCCESS';
export const FETCH_DAILY_ADMIN_STATS_BY_BRAND_FAILURE =
  'FETCH_DAILY_ADMIN_STATS_BY_BRAND_FAILURE';

export const FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_REQUEST =
  'FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_REQUEST';
export const FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_SUCCESS =
  'FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_SUCCESS';
export const FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_FAILURE =
  'FETCH_MONTHLY_ADMIN_STATS_BY_BRAND_FAILURE';

export const FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_REQUEST =
  'FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_REQUEST';
export const FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_SUCCESS =
  'FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_SUCCESS';
export const FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_FAILURE =
  'FETCH_WEEKLY_ADMIN_STATS_BY_BRAND_FAILURE';

export const FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_REQUEST =
  'FETCH_AVERAGE_CLICKBID_REQUEST';
export const FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_SUCCESS =
  'FETCH_AVERAGE_CLICKBID_SUCCESS';
export const FETCH_AVERAGE_CLICK_BID_BY_COUNTRY_FAILURE =
  'FETCH_AVERAGE_CLICKBID_FAILURE';

export const VALIDATE_CAMPAIGN_REQUEST = 'VALIDATE_CAMPAIGN_REQUEST';
export const VALIDATE_CAMPAIGN_SUCCESS = 'VALIDATE_CAMPAIGN_SUCCESS';
export const VALIDATE_CAMPAIGN_FAILURE = 'VALIDATE_CAMPAIGN_FAILURE';

export const REJECT_CAMPAIGN_REQUEST = 'REJECT_CAMPAIGN_REQUEST';
export const REJECT_CAMPAIGN_SUCCESS = 'REJECT_CAMPAIGN_SUCCESS';
export const REJECT_CAMPAIGN_FAILURE = 'REJECT_CAMPAIGN_FAILURE';

export const SEND_EMAIL_TO_ADMIN_REQUEST =
  'SEND_EMAIL_TO_ADMIN_REQUEST';
export const SEND_EMAIL_TO_ADMIN_SUCCESS =
  'SEND_EMAIL_TO_ADMIN_SUCCESS';
export const SEND_EMAIL_TO_ADMIN_FAILURE =
  'SEND_EMAIL_TO_ADMIN_FAILURE';

// POS Software
export const CREATE_POS_PARTNER_REQUEST =
  'CREATE_POS_PARTNER_REQUEST';
export const CREATE_POS_PARTNER_SUCCESS =
  'CREATE_POS_PARTNER_SUCCESS';
export const CREATE_POS_PARTNER_FAILURE =
  'CREATE_POS_PARTNER_FAILURE';

export const GET_POS_PARTNERS_REQUEST = 'GET_POS_PARTNERS_REQUEST';
export const GET_POS_PARTNERS_SUCCESS = 'GET_POS_PARTNERS_SUCCESS';
export const GET_POS_PARTNERS_FAILURE = 'GET_POS_PARTNERS_FAILURE';

export const GET_POS_PARTNER_BY_ID_REQUEST = 'GET_POS_PARTNER_BY_ID_REQUEST';
export const GET_POS_PARTNER_BY_ID_SUCCESS = 'GET_POS_PARTNER_BY_ID_SUCCESS';
export const GET_POS_PARTNER_BY_ID_FAILURE = 'GET_POS_PARTNER_BY_ID_FAILURE';

export const GET_POS_BUSINESS_BY_ID_REQUEST = 'GET_POS_BUSINESS_BY_ID_REQUEST';
export const GET_POS_BUSINESS_BY_ID_SUCCESS = 'GET_POS_BUSINESS_BY_ID_SUCCESS';
export const GET_POS_BUSINESS_BY_ID_FAILURE = 'GET_POS_BUSINESS_BY_ID_FAILURE';

export const DELETE_POS_PARTNER_REQUEST = 'DELETE_POS_PARTNER_REQUEST';
export const DELETE_POS_PARTNER_SUCCESS = 'DELETE_POS_PARTNER_SUCCESS';
export const DELETE_POS_PARTNER_FAILURE = 'DELETE_POS_PARTNER_FAILURE';

export const UPDATE_POS_PARTNER_REQUEST = 'UPDATE_POS_PARTNER_REQUEST';
export const UPDATE_POS_PARTNER_SUCCESS = 'UPDATE_POS_PARTNER_SUCCESS';
export const UPDATE_POS_PARTNER_FAILURE = 'UPDATE_POS_PARTNER_FAILURE';

export const SET_SELECTED_PARTNER_TO_EDIT = 'SET_SELECTED_PARTNER_TO_EDIT';
export const CLEAR_SELECTED_PARTNER_TO_EDIT =
  'CLEAR_SELECTED_PARTNER_TO_EDIT';

export const SET_SELECTED_BUSINESS_TO_EDIT = 'SET_SELECTED_BUSINESS_TO_EDIT';
export const CLEAR_SELECTED_BUSINESS_TO_EDIT =
  'CLEAR_SELECTED_BUSINESS_TO_EDIT';

export const CREATE_POS_BUSINESS_REQUEST = 'CREATE_POS_BUSINESS_REQUEST';
export const CREATE_POS_BUSINESS_SUCCESS = 'CREATE_POS_BUSINESS_SUCCESS';
export const CREATE_POS_BUSINESS_FAILURE = 'CREATE_POS_BUSINESS_FAILURE';

export const GET_POS_BUSINESS_REQUEST = 'GET_POS_BUSINESS_REQUEST';
export const GET_POS_BUSINESS_SUCCESS = 'GET_POS_BUSINESS_SUCCESS';
export const GET_POS_BUSINESS_FAILURE = 'GET_POS_BUSINESS_FAILURE';

export const DELETE_POS_BUSINESS_REQUEST = 'DELETE_POS_BUSINESS_REQUEST';
export const DELETE_POS_BUSINESS_SUCCESS = 'DELETE_POS_BUSINESS_SUCCESS';
export const DELETE_POS_BUSINESS_FAILURE = 'DELETE_POS_BUSINESS_FAILURE';

export const UPDATE_POS_BUSINESS_REQUEST = 'UPDATE_POS_BUSINESS_REQUEST';
export const UPDATE_POS_BUSINESS_SUCCESS = 'UPDATE_POS_BUSINESS_SUCCESS';
export const UPDATE_POS_BUSINESS_FAILURE = 'UPDATE_POS_BUSINESS_FAILURE';

export const SET_SELECTED_PARTNER_ID = 'SET_SELECTED_PARTNER_ID';

// users by brand
export const GET_USERS_BY_BRAND = 'GET_USERS_BY_BRAND';

// create generic e-receipt CSI
export const CREATE_GENERIC_RECEIPT_CSI = 'CREATE_GENERIC_RECEIPT_CSI';
// create generic e-receipt Carrepos
export const CREATE_GENERIC_RECEIPT_CARREPOS = 'CREATE_GENERIC_RECEIPT_CARREPOS';
// delete generic e-receipt CSI
export const DELETE_GENERIC_RECEIPT_CSI = 'DELETE_GENERIC_RECEIPT_CSI';
// delete generic e-receipt Carrepos
export const DELETE_GENERIC_RECEIPT_CARREPOS = 'DELETE_GENERIC_RECEIPT_CARREPOS';
