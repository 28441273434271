import axios from 'axios';
import {
  getAuthorizationToken,
  URL_GET_POS_PARTNERS,
  URL_POS_SOFT_ADMIN,
} from './constantApi';
import { updatePosBusinessById, updatePosBusinessByRegisterId } from '../redux/actions/posActions';

const PosService = {
  createPosPartner: (partnerName,partnerArea) => {
    return getAuthorizationToken().then((token) => {
      return axios.post(
        `${URL_POS_SOFT_ADMIN}`,
        { name: partnerName,area:partnerArea },
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },
  getPosPartners: () => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${URL_GET_POS_PARTNERS}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  deletePosPartner: (partnerId) => {
    return getAuthorizationToken().then((token) => {
      return axios.delete(`${URL_POS_SOFT_ADMIN}${partnerId}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  // get pos partner by id
  getPosPartnerById: (partnerId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${URL_POS_SOFT_ADMIN}${partnerId}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  updatePosPartner: (partnerName, partnerSector, partnerId) => {
    return getAuthorizationToken().then((token) => {
      return axios.put(
        `${URL_POS_SOFT_ADMIN}${partnerId}`,
        { name: partnerName, area: partnerSector },
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

    // create pos business
  createPosItem: (partnerId, businessData) => {
    return getAuthorizationToken().then((token) => {
      return axios.post(
        `${URL_POS_SOFT_ADMIN}${partnerId}/item/`,
        businessData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  },

  getPosItem: (partnerId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${URL_POS_SOFT_ADMIN}${partnerId}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  // get pos business by id
  getPosItemById: (partnerId, itemId) => {
    return getAuthorizationToken().then((token) => {
      return axios.get(`${URL_POS_SOFT_ADMIN}${partnerId}/item/${itemId}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },


  deletePosItem: (partnerId,itemId) => {
    return getAuthorizationToken().then((token) => {
      return axios.delete(`${URL_POS_SOFT_ADMIN}${partnerId}/item/${itemId}`, {
        headers: { Authorization: `Token ${token}` },
      });
    });
  },
  // update pos business
  updatePosItemById: (partnerId, itemId, businessData) => {
    return getAuthorizationToken().then((token) => {
      return axios.put(
        `${URL_POS_SOFT_ADMIN}${partnerId}/item/${itemId}`,
        businessData,
        {
          headers: { Authorization: `Token ${token}` },
        }
      );
    });
  }
};

export default PosService;
