/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiSelect from "components/SuiSelect";
import SuiInput from "components/SuiInput";
import TableRequest from "./components/TableRequest";
import PageListing from "components/PagesListing";
import { ExportButton } from "components/ExportButton";



// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SimpleStatisticsCard from "examples/Cards/StatisticsCards/SimpleStatisticsCard";
// data
import useTable from "./data/dataTableData";
// common
import { KpiService } from "@sharecode/common/services/KpiService";
import { exportTable } from "@sharecode/common/utils/helpers";
import { URL_EXPORT_CLIENTS } from "@sharecode/common/services/constantApi";
import { setSnackbar } from "@sharecode/common/redux/actions/snack";


// routes
import { useRole } from "routes/ProtectedRoutes";

function ClientList(props) {
  const [listKpi, setListKpi] = useState([]);
  const tableData = useTable();
  const [searchParams, setSearchParams] = useSearchParams();
  const [sizeSelected, setSizeSelected] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [loadingExport, setLoadingExport] = useState(false);
  const disp = useDispatch();

  const role = useRole();
  useEffect(() => {
    KpiService.getKpiClientList().then((r) => {
      setListKpi(r);
    });
  }, []);

  const handleChangeSize = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", e.value);
    searchParams.set("search", "");
    setSizeSelected({ value: e.value, label: e.label });
    setSearchParams(searchParams);
  };

  const searchCtn = (e) => {
    searchParams.set("page", 1);
    searchParams.set("size", searchParams.get("size") ? searchParams.get("size") : 10);
    searchParams.set("search", e.target.value);
    setSearchParams(searchParams);
  };

const handleClientsExport = () => {
  setLoadingExport(true);

  const paramsValues = {
    search: searchParams.get("search") || "",
    page: searchParams.get("page") || "1",
    pageSize: searchParams.get("size") || "10"
  };

  exportTable({ url: URL_EXPORT_CLIENTS, fileName: "clients.xlsx" }, paramsValues)
    .then((response) => {
      // Handle success
      disp(setSnackbar(true, "success", "success", "Export successful"));
    })
    .catch((error) => {
      disp(setSnackbar(true, "error", "error", "No entry to export"));
    })
    .finally(() => {
      setLoadingExport(false);
    });
};

    useEffect(() => {
    const search = searchParams.get("search") || "";
    const size = searchParams.get("size")
      ? { value: searchParams.get("size"), label: searchParams.get("size") }
      : { value: "10", label: "10" };

    setSearchValue(search);
    setSizeSelected(size);
  }, [searchParams]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={0}>

        <Card>
          <SuiBox p={2}>
            <Grid container spacing={3} display="flex">
              <Grid item md={6}>
                <SuiBox>
                  <SuiTypography variant="h6" fontWeight="medium">
                    Vos consommateurs
                  </SuiTypography>
                </SuiBox>
              </Grid>

              <Grid item display="flex" position="absolute" right={0} marginRight={2}>
                <SuiBox display="flex">
                <ExportButton
                  disabled={props.client?.count === 0}
                  handleExport={handleClientsExport}
                  loading={loadingExport}
                />

                </SuiBox>
              </Grid>
              {/* <Grid item>
                <IconButton color="dark">
                  <FilterAltIcon />
                </IconButton>
              </Grid> */}
            </Grid>
          </SuiBox>
          <SuiBox p={2} display="flex">
            <SuiBox display="flex">
              <SuiBox justifyContent="left">
                <SuiSelect
                  placeholder="Elements par page"
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "50", label: "50" },
                    { value: "100", label: "100" },
                    { value: "200", label: "200" },
                  ]}
                  onChange={(e) => handleChangeSize(e)}
                  value={{
                    value: searchParams.get("size") ? searchParams.get("size") : "10",
                    label: searchParams.get("size") ? searchParams.get("size") : "10",
                  }}
                />
              </SuiBox>
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "16px",
                }}
              >
                Total: {props.listClients.count}
              </div>
            </SuiBox>
            <SuiBox style={{ flex: "50%" }}>
              <SuiBox justifyContent="right" style={{ float: "right" }}>
                <SuiInput
                  placeholder="Recherche..."
                  icon={{
                    component: "search",
                    direction: "right",
                  }}
                  onKeyUp={(e) => searchCtn(e)}
                />
              </SuiBox>
            </SuiBox>
          </SuiBox>
          <TableRequest />

          {/* <DataTable table={tableData} canSearch /> */}
        </Card>
        <PageListing data={props.listClients} />
      </SuiBox>
    </DashboardLayout>
  );
}
function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(ClientList);
