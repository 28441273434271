// @ts-nocheck
// @ts-ignore
import { IS_ADMIN_VALUE } from '@sharecode/common/redux/actions/actionTypes';
import StorageUtil from '../utils/storageUtil';
import { isMobile } from '../utils/isMobile';

export const HMD_BACKEND_API_URL =
  process.env.REACT_APP_ROOT_API || 'https://dash-uat.app-check.fr/api';

 export const CHECK_BACKEND_API_URL =
  process.env.REACT_CHECK_APP_ROOT_API || 'https://api-uat.app-check.fr/api/v1';



export const AUTH_TOKEN_KEY = 'token';

// Account
export const URL_AUTHENT =
  HMD_BACKEND_API_URL + '/accounts/back-office/login/';
export const URL_REGISTER =
  HMD_BACKEND_API_URL + '/accounts/register/';
export const URL_LOGOUT = HMD_BACKEND_API_URL + '/accounts/logout/';
export const URL_VERIFY_EMAIL =
  HMD_BACKEND_API_URL + '/accounts/register/verify-email/';
export const URL_ACCOUNT_PROFILE =
  HMD_BACKEND_API_URL + '/accounts/profile/';
export const URL_ACCOUNT_DELETE =
  HMD_BACKEND_API_URL + '/accounts/delete/';
export const GET_DEFAULT_EXPENSE_REPORT_EMAIL =
  HMD_BACKEND_API_URL +
  '/accounts/profile/default-expense-report-email/';
export const URL_RESET_PASSWORD =
  HMD_BACKEND_API_URL + '/accounts/password/reset/';
export const URL_CONFIRM_PASSWORD =
  HMD_BACKEND_API_URL + '/accounts/password/reset/confirm/';
export const URL_AUTHENT_LINKEDIN =
  HMD_BACKEND_API_URL + '/accounts/login/linkedin/';
export const URL_AUTHENT_GOOGLE =
  HMD_BACKEND_API_URL + '/accounts/login/google/';

// Brand
export const URL_POST_BRAND = HMD_BACKEND_API_URL + '/brands/';
export const URL_BRAND_CATEGORIES =
  HMD_BACKEND_API_URL + '/brands/categories/';
export const URL_EXPORT_BRANDS =
  HMD_BACKEND_API_URL + '/brands/export/';

// Mobile homepage
export const URL_MOBILE_HOMEPAGE =
  HMD_BACKEND_API_URL + '/accounts/app/homepage/';

// Mobile homepage PRO

export const URL_MOBILE_HOMEPAGE_PRO =
  HMD_BACKEND_API_URL + '/accounts/app/homepage/pro';

// Brand Mobile
export const URL_GET_BRANDS = HMD_BACKEND_API_URL + '/brands/app/';

// List Shop of a brand Mobile

export const URL_GET_SHOPSOFBRANDS = HMD_BACKEND_API_URL + '/brands/';

export const URL_GET_TICKETS_NOT_APPOINTED =
  HMD_BACKEND_API_URL + '/brands/';

// List Shop of a brand Mobile P¨ro

export const URL_GET_SHOPSOFBRANDSPRO =
  HMD_BACKEND_API_URL + '/brands/';

export const URL_SEARCH_BRAND =
  HMD_BACKEND_API_URL + '/brands/search/?search=';

// Tickets
export const URL_GET_TICKETS_ADMIN =
  HMD_BACKEND_API_URL + '/tickets/';
export const URL_GET_TICKETS = HMD_BACKEND_API_URL + '/tickets/app/';
export const URL_EXPORT_TICKETS =
  HMD_BACKEND_API_URL + '/tickets/export/';
export const URL_POST_SCAN_IMAGE = HMD_BACKEND_API_URL + '/tickets/';
export const URL_POST_SCAN_IMAGE_PRO =
  HMD_BACKEND_API_URL + '/tickets/pro/';
export const URL_GET_TICKETS_NOT_APPOINTED_PRO =
  HMD_BACKEND_API_URL + '/brands/';
export const URL_GET_DELETE_TICKET =
  HMD_BACKEND_API_URL + '/tickets/';

export const URL_GET_TICKETSOFASHOP =
  HMD_BACKEND_API_URL + '/brands/shops/';

// Ticket Mobile PRO

export const URL_GET_TICKETS_PRO =
  HMD_BACKEND_API_URL + '/tickets/app/pro';

//Shop
export const URL_POST_SHOP = HMD_BACKEND_API_URL + '/brands/shops/';
//User
export const URL_POST_USER = HMD_BACKEND_API_URL + '/users/';
export const URL_EXPORT_USERS =
  HMD_BACKEND_API_URL + '/users/export/';

// KPI
export const URL_GET_KPI_DASHBOARD =
  HMD_BACKEND_API_URL + '/stats/dashboard/';
export const URL_GET_KPI_CLIENTXP =
  HMD_BACKEND_API_URL + '/stats/client-xp/';
export const URL_GET_KPI_CLIENTLIST =
  HMD_BACKEND_API_URL + '/stats/clients/';

//// KPIs v1
export const URL_GET_USER_PER_WEEK =
  HMD_BACKEND_API_URL + '/stats/user-per-week/';
export const URL_GET_ETICKETS_PER_WEEK =
  HMD_BACKEND_API_URL + '/stats/etickets-per-weeks/';
export const URL_GET_ACTIVITY_PER_AREA =
  HMD_BACKEND_API_URL + '/stats/activity-area/';

//// KPIs v2
// Dynamic charts & KPIs APIs
export const URL_GET_STATS_KPIS =
  HMD_BACKEND_API_URL + '/stats/kpis/';
export const URL_GET_NB_NEW_USERS =
  HMD_BACKEND_API_URL + '/stats/charts/users/';
export const URL_GET_TICKETS_DISTRIBUTION =
  HMD_BACKEND_API_URL + '/stats/charts/tickets/';
export const URL_GET_NB_NDF =
  HMD_BACKEND_API_URL + '/stats/charts/expense-reports/';
export const URL_GET_NB_LOYALTY_CARDS =
  HMD_BACKEND_API_URL + '/stats/charts/loyalty-cards/';
// Static charts & KPIs APIs
export const URL_GET_NB_COMMENTS =
  HMD_BACKEND_API_URL + '/stats/charts/comments/';
export const URL_GET_AREAS_DISTRIBUTION =
  HMD_BACKEND_API_URL + '/stats/charts/areas-distribution/';
export const URL_GET_COUNT_USERS =
  HMD_BACKEND_API_URL + '/stats/count-users/';
export const URL_GET_COUNT_PRO_USERS =
  HMD_BACKEND_API_URL + '/stats/count-pro-users/';

export const URL_GET_CLIENT_FIDELITY =
  HMD_BACKEND_API_URL + '/stats/client-fid/';
export const URL_GET_FEMALE_MALE_CHART =
  HMD_BACKEND_API_URL + '/stats/chart-female-male/';

export const URL_GET_PRO_PERSO_CHART =
  HMD_BACKEND_API_URL + '/stats/pro-perso/';

export const URL_GET_ETICKETS_SCAN_CHART =
  HMD_BACKEND_API_URL + '/stats/e-ticket-scan/';

// Comments
export const URL_LIST_CREATE_COMMENTS =
  HMD_BACKEND_API_URL + '/comments/';
export const URL_EXPORT_LIST_COMMENTS =
  HMD_BACKEND_API_URL + '/comments/export/';

// Send expense report by mail
export const URL_SEND_EXPENSE_REPORT =
  HMD_BACKEND_API_URL + '/tickets/settings-pro/';

// Clients
export const URL_GET_LIST_CLIENTS =
  HMD_BACKEND_API_URL + '/brands/customers/';
export const SEND_COMMENT_TO_CLIENT =
  HMD_BACKEND_API_URL + '/comments/';



// Offers
export const SEND_OFFER_TO_CLIENTS = HMD_BACKEND_API_URL + '/offers/';

export const parameterRequest = {
  page: 'page',
  size: 'size',
  search: 'search',
  startDate: 'start_date',
  endDate: 'end_date',
  area: 'area',
  unassigned: 'unassigned',
  paperTickets: 'paper_tickets',
  pagesize: 'pagesize',
  role: 'role',
};

// Chat
export const URL_GET_CONVERSATIONS =
  HMD_BACKEND_API_URL + '/conversations/';

export const URL_GET_CONVERSATIONS_MOBILE =
  HMD_BACKEND_API_URL + '/conversations/app/';

// notifications
export const URL_GET_LIST_NOTIFICATIONS_SETTINGS =
  HMD_BACKEND_API_URL + '/accounts/app/notifications-settings/';
export const URL_GET_ALERT_OFFER =
  HMD_BACKEND_API_URL + '/accounts/app/notifications-settings/brand/';
export const URL_REGISTER_DEVICE =
  HMD_BACKEND_API_URL + '/notifications/register/';
export const URL_UNREGISTER_DEVICE =
  HMD_BACKEND_API_URL + '/notifications/logout/';
export const URL_LIST_NOTIFICATIONS =
  HMD_BACKEND_API_URL + '/notifications/';

//Get Auth Token
export const getAuthorizationToken = async () => {
  return await StorageUtil.getItem(AUTH_TOKEN_KEY);
};

export const getUserInfos = () => {
  let user = StorageUtil.getItem('user');
  // @ts-ignore
  user = JSON.parse(user).user;
  return user;
};

export const getUserBrand = () => {
  if (!isMobile) {
    StorageUtil.getItemTest('user').then((response) => {
      let user = response;
      // @ts-ignore
      user = JSON.parse(user).user;
      // @ts-ignore
      if (user.role !== IS_ADMIN_VALUE) return user.brand.id;
    });
  } else return null;
};

export const setAuthorizationToken = (token) => {
  StorageUtil.setItem(AUTH_TOKEN_KEY, token);
};

export const clearSession = () => {
  StorageUtil.removeItem(AUTH_TOKEN_KEY);
  StorageUtil.removeItem('user');
  StorageUtil.removeItem('role');
};

export const useAuth = () => {
  return StorageUtil.getItem('user');
};

export const useRole = () => {
  return StorageUtil.getItem('role');
};

let user = StorageUtil.getItem('user');

export const getUserPicture = () => {
  if (user) {
    if (!isMobile) {
      // @ts-ignore
      user = JSON.parse(user);
      // @ts-ignore
      return user?.user?.picture ? user?.user?.picture : null;
    } else {
      // @ts-ignore
      StorageUtil.getItem('user').then((response) => {
        let user = response;
        user = JSON.parse(user);
        return user.user.picture;
      });
    }
  } else return;
};

/// Determine the base URL for API calls based on the current window location
const getApiBaseUrl = () => {
  const hostname = window.location.hostname;

  switch (true) {
    case hostname.includes('localhost'):
      // Local development environment
      return 'https://api-uat.app-check.fr';

    case hostname.includes('dash-uat.app-check.fr'):

      // UAT environment
      return 'https://api-uat.app-check.fr';

    default:
      // Production environment
      return 'https://api.app-check.fr';
  }
};

// Base API URL
export const API_BASE_URL = getApiBaseUrl();

// retailer ads base URL
export const RETAILER_ADS_BASE_URL =
  API_BASE_URL + '/api/v1/retailer';
// auth base url
export const AUTH_BASE_URL = API_BASE_URL + '/api/v1/auth';
// stats by date base url
export const STATS_BY_DATE_BASE_URL =
  API_BASE_URL + '/api/v1/retailer/stat/date/ads/timeperiod';

// admin stats by date base url
export const ADMIN_STATS_BY_DATE_BASE_URL =
  API_BASE_URL + '/api/v1/retailer/stat/admin/date/ads/brands';

// url to notify admin for email campaign approval
export const URL_NOTIFY_ADMIN_EMAIL_CAMPAIGN_APPROVAL = 'https://mail.app-check.fr/api/v1/mail'
// CHECK endpoint to get list of users
export const URL_GET_USERS = API_BASE_URL + '/api/v1/users/list';
// CHECK endpoint to get list of users for each brand
export const URL_GET_USERS_BY_BRAND = API_BASE_URL + '/api/v1/brand/users?ticketType=digital';
export const URL_EXPORT_CLIENTS = API_BASE_URL + '/api/v1/brand/users/export?ticketType=digital';

// base url for pos software
export const URL_POS_SOFT_ADMIN = API_BASE_URL + '/api/v1/possoft/admin/';

// POS software admin endpoint: get list of partners
export const URL_GET_POS_PARTNERS = URL_POS_SOFT_ADMIN + 'list';
// CHECK endpoint to create a generic e-receipt CARREPOS
export const URL_CREATE_GENERIC_ERECIPT_CARREPOS = API_BASE_URL + '/api/v1/usefuls/admin/demo/receipt/carrepos';
// CHECK endpoint to create a generic e-receipt CSI
export const URL_CREATE_GENERIC_ERECIPT_CSI = API_BASE_URL + '/api/v1/usefuls/admin/demo/receipt/csi';




